<template>
  <v-container>
    <v-row align="center">
      <v-col
        sm="8"
        md="8"
        lg="6"
        xl="4"
        class="mx-auto"
      >
<!--        <div
          class="text-center"
        >
          <img
            :src="require('../assets/lamiapratica_nero.png')"
            height="30px"
            alt=""
          >
        </div>-->
        <v-card
          class="mx-auto pa-4 pa-sm-6 pa-md-16 my-4"
          elevation="6"
          style="max-width: 600px;"
        >
          <v-card-text>
            <v-row>
              <v-col>
                <h2 class="mb-2 text-center">
                  Oops! Qualcosa è andato storto...
                </h2>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions
            class="d-flex justify-center"
          >
            <v-btn
              color="primary"
              :loading="loading.refresh"
              @click="tryRefresh"
            >
              Ricarica
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'Refresh',
  data: () => ({
    loading: {
      refresh: false,
    },
  }),
  computed: {
    ...mapGetters(['formRules', 'progress']),
  },
  methods: {
    tryRefresh() {
      this.loading.refresh = true
      this.$store.dispatch('refresh')
        .then(() => {
          this.loading.refresh = false
          this.$router.push({ name: 'home' })
        })
        .catch(() => {
          this.loading.refresh = false
        })
    },
  },
}
</script>
<style scoped>

</style>
